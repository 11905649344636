import {
    get,
    post,
    post1
} from './http.js';
export const apiGetIndex = p => get('index/index', p); //首页

export const apiGetConfig = p => get('index/get_config', p) //获取底部文字
export const apiGetConfig1 = p => get('index/get_config1', p) //获取底部文字
export const apiEmsSend   = p =>post('ems/send',p);//获取验证码
export const userLogoin   = p =>post('login/login',p)//登录
export const userRegister = p =>post('login/register',p)//注册
export const ForgetPassword = p=>post('login/np',p)//忘记密码
export const Logout  = p => post('user/logout',p)//退出登录
export const UpLoadImg = p => post('common/upload',p)//退出登录

export const apiGetGoods = p =>get('goods/goodslist',p)//商品列表
export const apiGetGoodsDetail = p =>get('goods/goodsdetail',p);//商品详情页
export const apiGetGoodsComment =p =>get('goods/getComments',p)//商品评论
export const apiGetMyOrder = p =>get('mine/myOrders',p)//商品详情页
export const apiGetMyOrderDetail = p =>get('mine/order_detail',p)//订单详情页
export const apiGetNews = p =>get('mine/myNews',p)//我的消息
export const apiGetHelps = p =>get('index/getHelps',p)//帮助中心
export const apiGetDetail = p =>get('index/getDetail',p);//获取详情页
export const apiGetCategory = p =>get('index/getCategoryList',p);//全部分类
export const apiGetIsgo  = p =>get('index/goBannerGoods',p);
export const apiGetPrice = p =>get('goods/keyimage',p);//价格
export const apiGetIsBuy = p =>get('index/isbuy',p)//是否可以购买
export const apiGetGetMoney = p =>get('goods/getPrice',p);//获取不同币种直接的价格
export const apiGetOrderShow = p =>get('order/ordershow',p)//获取订单详情
export const apiGetMyComment = p =>get('mine/myComments',p)//获取评价

export const apiGetMyCommentDetail = p=>get('mine/myCommentsDetail',p)//获取评价详情
export const apiGetUserInfo = p =>get('login/info',p);//获取
export const apiGetkefu =p =>get('mine/mykefu',p)
export const apiGetCategoryDetail =p =>get('goods/getCategory',p)
export const apiGetCheckStock =p =>get('goods/checkstock',p)//
export const apiGetCancel =p =>get('order/cancelOrder',p)


//post提交
export const apiGetForcheck = p =>post('login/check_code',p)//
export const apiForget = p=>post('login/resetpwd_login',p)
export const  apiSaveOrder = p =>post('order/saveOrder',p)//提交订单
export const  apiSaveComment = p =>post('order/saveComments',p)//提交评价
export const  apiSaveInfo = p =>post('mine/saveInfo',p)//更新信息
export const  apiPayagain = p =>post('order/payAgain',p)//重新支付




//获取百度IP地址


// 个人中心 

