<template>
  <div class="footer">
    <div class="footer_box flex-box">
      <router-link to="/">
      <img class="login_icon" :src="logo1" alt="" />
      </router-link>
      <div class="flex-grow-1 flex">
        <div class="flex-grow-1 flex flex-wrap pr fs14 pointer">
          <router-link class="w50 fs20 colf lh28 fwb plans" to="/article?id=1"
            >关于我们</router-link
          >
          <router-link class="w50 fs20 colf lh28 fwb plans" to="/article?id=3"
            >服务支持</router-link
          >

          <router-link class="w50 plans" to="/article?id=1">关于我们</router-link>
          <router-link class="w50 plans" to="/article?id=5">关于支付</router-link>
          <router-link class="w50 plans" to="/article?id=6">联系我们</router-link>
          <router-link class="w50 plans" to="/article?id=2">支付流程</router-link>
          <router-link class="w50 plans" to="/article?id=4">充值流程</router-link>
          <router-link class="w50 plans" to="/helpCenter">帮助中心</router-link>
        </div>
        <div class="tc">
          <img :src="logo" class="code" alt="" />
          <div class="tc">扫码联系客服</div>
        </div>
      </div>
    </div>
    <div class="tc bottom">
      {{beian}}
    </div>
  </div>
</template>
<script>
import {apiGetConfig1} from '../request/api.js';
export default {
  data() {
    return {
      plans: [],
      wechat_qrcode: "",
      logo1:'../../assets/images/logo2.png',
      logo:'../../assets/images/logo1.png',
      beian:'COPYRIGHT@上海邦颂文化有限公司1989-2013备案号: 沪ICP备09066491号-5'
    };
  },
  created() {
    var logo = "logo2";
    this.logo1 = require("../assets/images/" + logo + ".png");
   var logo2 = "foot_code";
    this.logo = require("../assets/images/" + logo2 + ".png");

    this.get_config();
  },
  methods: {



    // 获取数据
    get_config() {
      var that =this;
      // 调用api接口，并且提供了两个参数
      // 调用api接口，并且提供了两个参数
      apiGetConfig1({

      }).then(res => {
        // 获取数据成功后的其他操作
        that.logo1=res.data.logo_f?res.data.logo_f:'';
        that.beian=res.data.beian;
        that.logo=res.data.kefu
      }).catch(err=>{

      })
    }
  },
};
</script>
<style scoped>
.footer {
  width: 100vw;
  background-color: #0f161a;
  color: rgba(255, 255, 255, 0.5);
}

.bottom {
  padding: 11px 0 16px 0;
  border-top: 1px solid rgba(151, 151, 151, 0.5);
}
.footer_box {
  padding: 34px 227px 23px 187px;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
}
.footer_box::after {
  content: "";
  display: block;
  width: 1px;
  height: 111px;
  background: rgba(151, 151, 151, 0.2);
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  left: 455px;
}

.footer_box a {
  color: rgba(255, 255, 255, 0.5);
}
.login_icon {
  width: 219px;
  height: 83px;
  display: block;
  object-fit: contain;
  margin-right: 173px;
}
.code {
  width: 138px;
  height: 138px;
  object-fit: cover;
  -o-object-fit: cover;
  margin-bottom: 14px;
}
.pr50 {
  padding-right: 50px;
}
@media screen and (max-width: 1640px) {
  .footer_box {
    margin: 0 30px;
  }
}
.pr {
  position: relative;
}
.w50 {
  width: 50%;
  margin-bottom: 10px;
}
.colf {
  color: #fff !important;
}
.plans:hover {
  color: #fff;
  opacity: 1;
}
</style>
